import { ref } from "vue";
import type { TableData } from "@/components/GkTable";

// 配置项
interface ConfigTypes {
    selectionIdName?: string;
    pageSize?: number;
}

export default function (config: ConfigTypes) {
    // 表格数据
    let tableData = ref<TableData>([]);

    // 表格分页
    let tablePagination = ref({
        current: 1,
        size: config.pageSize || 10,
        total: 0,
    });

    // 选中
    let selectionIds = ref<Array<string | number>>([]);

    let changeSelectionIds = (val: Array<any>) => {
        selectionIds.value = val.map((item) => item[config.selectionIdName || "id"]);
    };

    return {
        tableData,
        tablePagination,
        selectionIds,
        changeSelectionIds,
    };
}
